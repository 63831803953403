<template>
	<b-row>
		<b-col cols="12">
			<!-- 1 -->
			<b-card no-body class="mb-4">
				<!-- Search Control -->
				<b-card-body class="p-3 d-flex justify-content-between">
					<b-form-group class="mb-2 w-25">
						<b-input-group>
							<b-form-input id="filter-input" v-model="filter" type="search" placeholder="Type to Search"></b-form-input>

							<b-input-group-append>
								<b-button variant="danger" :disabled="!filter" @click="filter = ''">Clear</b-button>
							</b-input-group-append>
						</b-input-group>
					</b-form-group>
					<div class="d-flex align-items-center">
						<b-button variant="primary" class="mr-2" v-b-modal.modal-countries @click="titleModal = 'CREATE'">Add</b-button>
					</div>
				</b-card-body>
				<!-- End Section -->

				<!-- Data Table -->
				<b-table
					style="min-height: 250px;"
					show-empty
					responsive
					small
					class="mb-0"
					head-variant="light"
					:items="countries"
					:filter="filter"
					:fields="fields"
					hover
				>
					<template #cell(id)="data"> #{{ data.item.id }} </template>
					<template #cell(isActive)="data">
						<b-badge variant="success" v-if="data.item.isActive">Active</b-badge>
						<b-badge variant="danger" v-else>Inactive</b-badge>
					</template>
					<template #cell(action)="data">
						<b-dropdown variant="primary" id="dropdown-1" text="Actions" class="">
							<b-dropdown-item v-b-modal.modal-countries @click="editCountry(data.item)">Edit</b-dropdown-item>
							<!-- <b-dropdown-item v-b-modal.modal-remove>Delete</b-dropdown-item> -->
						</b-dropdown>
					</template>
				</b-table>
				<!-- End Section -->

				<!-- Pagination -->
				<div class="p-3 d-flex align-items-center">
					<b-form-group
						label="Per page"
						label-for="per-page-select"
						label-cols-sm="6"
						label-cols-md="4"
						label-cols-lg="9"
						label-size="sm"
						label-class="fw-medium"
						class="mb-0"
					>
						<b-form-select @change="getCountries" id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
					</b-form-group>
					<div class="ml-auto">
						<b-pagination
							@input="getCountries"
							v-model="currentPage"
							:total-rows="totalCountries"
							:per-page="perPage"
							align="fill"
							class="my-0"
						></b-pagination>
					</div>
				</div>
				<!-- End Section -->
			</b-card>
		</b-col>

		<!-- Modal -->
		<b-modal id="modal-countries" hide-footer centered :title="titleModal" @close="closeModal">
			<b-form @submit.prevent="createCountries">
				<b-form-group label="Contry" label-class="fw-medium mb-1" label-for="name">
					<b-form-input id="name" type="text" placeholder="Country name here" required v-model="name" autocomplete="off"></b-form-input>
				</b-form-group>
				<b-form-group label="Code" label-class="fw-medium mb-1" label-for="code">
					<b-form-input id="code" type="text" placeholder="Code here" required v-model="code" autocomplete="off"></b-form-input>
				</b-form-group>
				<b-form-group label="ISO Code" label-class="fw-medium mb-1" label-for="isO_Code">
					<b-form-input id="isO_Code" type="text" placeholder="ISO Code here" required v-model="isO_Code" autocomplete="off"></b-form-input>
				</b-form-group>

				<b-button block :disabled="loadingCountry" variant="primary" type="submit">
					<b-spinner small v-if="loadingCountry"></b-spinner>
					<span v-else>Save</span>
				</b-button>
			</b-form>
		</b-modal>

		<b-modal id="modal-remove" size="sm" hide-footer centered title="Delete Country">
			<b-card-text>
				Are you sure to want to remove this country?
			</b-card-text>
			<div class="d-flex justify-content-end">
				<!-- <b-button variant="danger" class="mr-2" @click="removeCountry">Yes</b-button> -->
				<b-button variant="danger" class="mr-2">Yes</b-button>
				<!-- <b-button variant="secondary" @click="$bvModal.hide('modal-remove')">No</b-button> -->
				<b-button variant="secondary">No</b-button>
			</div>
		</b-modal>
		<!-- End Section -->
	</b-row>
</template>

<script>
import { mapGetters } from "vuex";
export default {
	name: "countries",

	data: () => ({
		page: {
			title: "countries",
		},
		filter: null,
		fields: [
			{
				key: "id",
				label: "No",
				sortable: true,
			},
			{
				key: "name",
				label: "Contry",
				sortable: true,
			},
			{
				key: "code",
				label: "Code",
				sortable: true,
			},
			{
				key: "isO_Code",
				label: "ISO Code",
				sortable: true,
			},
			{
				key: "action",
				label: "Actions",
				sortable: false,
			},
		],

		totalRows: 1,
		currentPage: 1,
		perPage: 10,
		pageOptions: [5, 10, 20, 40, { value: 100, text: "Show a lot" }],
		titleModal: "Create Country",
		loadingCountry: false,
		name: "",
		code: "",
		isO_Code: "",
		countrySelected: {},
	}),
	components: {},
	methods: {
		clearValues() {
			this.name = "";
			this.code = "";
			this.isO_Code = "";
			this.countrySelected = {};
		},
		closeModal() {
			this.isEditing = false;
			this.clearValues();
			this.titleModal = "Create Country";
		},
		async editCountry(country) {
			const countryData = await this.$store.dispatch("countries/get_countries", country.id);
			this.isEditing = true;
			this.countrySelected = countryData;
			this.name = countryData.name;
			this.code = countryData.code;
			this.isO_Code = countryData.isO_Code;
			this.titleModal = "EDIT";
		},
		async getData() {
			await this.getCountries();
			await this.$store.dispatch("authorizations/get_allAuthorizations");
		},
		async getCountries() {
			let data = {
				skip: this.currentPage,
				take: this.perPage,
			};
			await this.$store.dispatch("countries/get_allCountries", data);
		},
		async createCountries() {
			this.loadingCountry = true;
			let data = {
				name: this.name,
				code: this.code,
				isO_Code: this.isO_Code,
			};

			if (this.isEditing) {
				(data.id = this.countrySelected.id), await this.$store.dispatch("countries/update_country", data);
			} else {
				await this.$store.dispatch("countries/create_countries", data);
			}

			this.clearValues();
			await this.getCountries();
			this.$bvModal.hide("modal-countries");
			this.loadingCountry = false;
		},
	},
	computed: {
		...mapGetters({
			countries: "countries/getCountries",
			totalCountries: "countries/getTotalCountries",
			authorizations: "authorizations/getAuthorizations",
		}),
	},
	mounted() {},
	created() {
		this.getData();
	},
};
</script>
